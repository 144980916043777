import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`We welcome the efforts of our community of users to develop robust, unofficial
tools for use with our products and services. One of the reasons that we keep
our APIs open is to encourage development and collaboration as you put our data
to use.`}</p>
    <div {...{
      "id": "toc-getting-started"
    }}><h2 {...{
        "id": "getting-started",
        "parentName": "div"
      }}>{`Getting Started`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Before you begin developing tools for use across the community, you should fully
familiarize yourself with the relevant official APIs and specifications:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/minfraud/api-documentation/#request-and-response-api-references",
            "parentName": "li"
          }}>{`minFraud service REST API`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/geoip/docs/web-services#request-and-response-api-references",
            "parentName": "li"
          }}>{`GeoIP2 and GeoLite2 service REST API`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://github.com/maxmind/MaxMind-DB/blob/main/MaxMind-DB-spec.md",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`GeoIP2 and GeoLite2 MMDB format specification`}</a></li>

      </ul>
      <p {...{
        "parentName": "div"
      }}>{`You may also want to look at some of the unofficial APIs that we’ve featured.
While MaxMind does not support or maintain unofficial APIs, you can get a
feeling for the quality of contributions that we feature:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://www.weltpixel.com/magento2-maxmind-fraud-prevention-minfraud.html",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`minFraud service Magento 2 Plugin`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/geoip/docs/web-services/#third-party-client-apis",
            "parentName": "li"
          }}>{`GeoIP2 and GeoLite2 web services Unofficial APIs`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/geoip/docs/databases/#unofficial-client-apis",
            "parentName": "li"
          }}>{`GeoIP2 and GeoLite2 database Unofficial APIs`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/geoip/docs/databases/#integrations",
            "parentName": "li"
          }}>{`GeoIP2 and GeoLite2 database Integrations (for MMDB format)`}</a></li>

      </ul>
      <p {...{
        "parentName": "div"
      }}>{`You can also peruse our organization and projects on GitHub to get a sense of
how we develop for MaxMind’s products and services. Some good examples to look
at are:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://github.com/maxmind/MaxMind-DB-Reader-ruby",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`Ruby MMDB Reader`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://github.com/maxmind/minfraud-api-dotnet",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`minFraud .NET API`}</a></li>

      </ul></div>
    <div {...{
      "id": "toc-what-tools-are-useful-to-build"
    }}><h2 {...{
        "id": "what-tools-are-useful-to-build",
        "parentName": "div"
      }}>{`What Tools are Useful to Build`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Well-developed client APIs in languages we don’t already support are especially
useful. We also appreciate efforts to develop plugins for popular content
management systems, as well as analytics and shopping cart software. In order
for your tools to be featured on our developer portal, they must work with one
of our paid products or services (GeoIP2 or minFraud). GeoLite2 databases and
service integrations should be compatible with GeoIP2 products and services with
minor modifications.`}</p></div>
    <div {...{
      "id": "toc-sharing-your-tools"
    }}><h2 {...{
        "id": "sharing-your-tools",
        "parentName": "div"
      }}>{`Sharing Your Tools`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`If you would like us to feature an API or tool that you’ve developed for use
with our products and services, please send us an email at `}
        <a {...{
          "href": "mailto:support@maxmind.com",
          "parentName": "p"
        }}>{`support@maxmind.com`}</a>
        {`
with a brief description of the tool and a link to the code and documentation.
We do not guarantee that we will feature all contributions on our site, but
we’re always interested to see what you come up with.`}</p></div>
    <div {...{
      "id": "toc-refer-users-to-maxmind"
    }}><h2 {...{
        "id": "refer-users-to-maxmind",
        "parentName": "div"
      }}>{`Refer Users to MaxMind`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`If you develop a useful third-party integration built to use MaxMind data, you
may be interested in referring your users to MaxMind through our Affiliate
Program.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`You can
`}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/sections/5066199198619-Affiliate-Program",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`learn more about the MaxMind Affiliate Program for developers on our Knowledge Base`}</a>
        {`,
including how to apply.`}</p></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      